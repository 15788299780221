<template>
  <div class="fab-container">
    <div class="fab-parent fab-icon-holder">
      <i class="fa fa-chevron-up"></i>
    </div>
    <ul class="fab-options">
      <li
        v-if="checkPrivilege(usersPrivilegeData.usersPrivilegeBrandUsers.add)"
      >
        <span class="fab-label">
          <router-link :to="{ name: 'BrandUserAdd' }" class="">
            {{ $t("BrandUsers.add") }}
          </router-link>
        </span>
        <div class="fab-icon-holder">
          <router-link :to="{ name: 'BrandUserAdd' }" class="">
            <img src="@/assets/images/plus.svg" />
          </router-link>
        </div>
      </li>
      <li>
        <span class="fab-label" @click="open">
          {{ $t("filter") }}
        </span>
        <div class="fab-icon-holder" @click="open">
          <img src="@/assets/images/filter.svg" />
        </div>
      </li>
    </ul>

    <vue-bottom-sheet ref="CustomBottomSheet">
      <div class="row">
        <CustomInput
          :className="'col-md-12'"
          :id="'textSearch'"
          :value="filterData.textSearch"
          :title="$t('search')"
          :imgName="'search.svg'"
          v-on:changeValue="filterData.textSearch = $event"
        />

        <CustomSelectBox
          :className="'col-md-12'"
          :id="'userTypeToken'"
          :value="filterData.userTypeToken"
          :options="userTypeTokenOptions"
          v-on:changeValue="filterData.userTypeToken = $event"
          :title="$t('users.selectType')"
          :imgName="'type.svg'"
        />
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="search"
        >
          {{ $t("search") }}
        </button>
        <button class="btn btn-cancel" @click.prevent="close">
          {{ $t("cancel") }}
        </button>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { setDataMultiLang, checkPrivilege } from "@/utils/functions";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";

export default {
  props: {
    theFilterData: {
      type: Object,
    },
  },
  data() {
    return {
      filterData: this.theFilterData,
      userTypeTokenOptions: [],
    };
  },
  computed: {
    ...mapGetters(["constantsListsData", "usersPrivilegeData"]),
  },
  components: {
    CustomInput,
    CustomSelectBox,
  },
  methods: {
    open() {
      this.$refs.CustomBottomSheet.open();
    },
    close() {
      this.$refs.CustomBottomSheet.close();
    },
    search() {
      this.close();
      this.$emit("search", this.filterData);
    },
    async getDialogOfUserTypes() {
      this.isLoading = true;
      this.userTypeTokenOptions = [];
      this.userTypeTokenOptions.push({
        value: "",
        text: this.$t("users.selectType"),
      });
      let genders = this.constantsListsData.listUserType;
      for (let item in genders) {
        this.userTypeTokenOptions.push({
          value: genders[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    checkPrivilege,
  },
  async created() {
    this.getDialogOfUserTypes();
  },
};
</script>
