import axios from "axios";
import BrandUserImg from "@/assets/images/brandUsers.svg";
import BrandsImg from "@/assets/images/brand.svg";
import UsersImg from "@/assets/images/users.svg";
import { PAGE_SIZE } from "@/utils/constants";

export default class BrandUser {
  defaultImg = BrandUserImg;
  defaultImgBrand = BrandsImg;
  defaultImgUser = UsersImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.brandToken = "";
    this.establishmentToken = "";
    this.activityTypeToken = "";
    this.dailyCode = "";
    this.fullCode = "";
    this.brandNameCurrent = "";
    this.brandNameEn = "";
    this.brandNameAr = "";
    this.brandNameUnd = "";
    this.brandDiscountPercentage = "";
    this.mapLink = "";
    this.mapLinkStatus = false;
    this.faceBookLink = "";
    this.faceBookLinkStatus = false;
    this.twitterLink = "";
    this.twitterLinkStatus = false;
    this.instagramLink = "";
    this.instagramLinkStatus = false;
    this.webSiteLink = "";
    this.webSiteLinkStatus = false;
    this.brandDescriptionAr = "";
    this.brandDescriptionEn = "";
    this.brandDescriptionUnd = "";
    this.brandImagePath = "";
    this.brandNotes = "";
    this.brandArchiveStatus = false;
    this.usersData = [];
    this.usersTokensList = [];
  }
  fillData(data) {
    this.brandToken = data.brandToken;
    this.establishmentToken = data.establishmentToken;
    this.activityTypeToken = data.activityTypeToken;
    this.dailyCode = data.dailyCode;
    this.fullCode = data.fullCode;
    this.brandNameCurrent = data.brandNameCurrent;
    this.brandNameEn = data.brandNameEn;
    this.brandNameAr = data.brandNameAr;
    this.brandNameUnd = data.brandNameUnd;
    this.brandDiscountPercentage = data.brandDiscountPercentage;
    this.mapLink = data.mapLink;
    this.mapLinkStatus = data.mapLinkStatus;
    this.faceBookLink = data.faceBookLink;
    this.faceBookLinkStatus = data.faceBookLinkStatus;
    this.twitterLink = data.twitterLink;
    this.twitterLinkStatus = data.twitterLinkStatus;
    this.instagramLink = data.instagramLink;
    this.instagramLinkStatus = data.instagramLinkStatus;
    this.webSiteLink = data.webSiteLink;
    this.webSiteLinkStatus = data.webSiteLinkStatus;
    this.brandDescriptionAr = data.brandDescriptionAr;
    this.brandDescriptionEn = data.brandDescriptionEn;
    this.brandDescriptionUnd = data.brandDescriptionUnd;
    this.brandImagePath = data.brandImagePath;
    this.brandNotes = data.brandNotes;
    this.brandArchiveStatus = data.brandArchiveStatus;
    this.usersData = data.usersData;

    if (this.usersData) {
      for (let item in this.usersData) {
        // this.usersTokensList.push(this.usersData[item]["value"]);
        this.usersTokensList.push({
          value: this.usersData[item].userToken,
          text: this.usersData[item].userNameCurrent,
          image: this.usersData[item].userImagePath,
        });
      }
    }
  }

  async getAllBrandsIncludedUsers(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    return await axios.get(
      `/api/BrandUsers/GetAllBrandsIncludedUsers?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&pageSize=${PAGE_SIZE}&page=${pagination.selfPage}&filterStatus=true&textSearch=${filterData.textSearch}`
    );
  }

  async addOrUpdateBrandUser(
    language,
    userAuthorizeToken,
    updateStatus = false
  ) {
    let userTokenList = [];
    if (this.usersTokensList) {
      for (let item in this.usersTokensList) {
        userTokenList.push(this.usersTokensList[item]["value"]);
      }
    }

    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      brandToken: this.brandToken,
      userTokenList: userTokenList,
    };

    if (updateStatus)
      return await axios.post(`/api/BrandUsers/UpdateBrandUser`, data);
    else return await axios.post(`/api/BrandUsers/AddBrandUser`, data);
  }
}
