<template>
  <div v-if="brandUsers" class="">
    <div
      v-for="(brandUser, index) in brandUsers"
      :key="index"
      class="container-collapse-with-btns"
    >
      <b-button
        v-b-toggle="`item${index}`"
        class="btn btn-lg btn-collapse collapse-data"
      >
        <div class="p-t-container">
          <div class="row collapse-title">
            <div class="col-md-12 col-lg-12">
              <img
                class="item-img-table"
                :src="`${baseUrl}${brandUser.brandImagePath}`"
                :onerror="`this.src='${defaultImgBrand}'`"
              />
              {{ `${isDataExist(brandUser.brandNameCurrent)}` }}
            </div>
          </div>
          <div class="collapse-icon">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </div>
        </div>
      </b-button>
      <div class="collapse-actions btn-collapse">
        <div
          v-if="
            checkPrivilege(usersPrivilegeData.usersPrivilegeBrandUsers.update)
          "
        >
          <router-link
            :to="{
              name: 'BrandUserEdit',
              params: {
                brandUserData: brandUser,
              },
            }"
            :title="$t('edit')"
          >
            <img src="@/assets/images/pencil.svg" class="icon-lg" />
          </router-link>
        </div>
      </div>

      <b-collapse :id="`item${index}`">
        <div class="my-card">
          <!-- <span class="my-card-title">{{ ++indexMed }}</span> -->

          <div class="row">
            <DataLabelGroup
              :className="'col-lg-6'"
              :value="brandUser.brandNameCurrent"
              :title="$t('brands.name')"
              :imgName="'brand.svg'"
            />
            <DataLabelGroup
              :className="'col-lg-6'"
              :value="brandUser.fullCode"
              :title="$t('brands.code')"
              :imgName="'code.svg'"
            />
          </div>

          <div class="table-container">
            <table class="my-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t("users.name") }}</th>
                  <th>{{ $t("users.code") }}</th>
                  <th>{{ $t("users.phone") }}</th>
                  <th>{{ $t("users.email") }}</th>
                </tr>
              </thead>
              <tbody v-if="brandUser.usersData.length > 0">
                <tr
                  v-for="(user, indexMed) in brandUser.usersData"
                  :key="indexMed"
                >
                  <td>{{ ++indexMed }}</td>
                  <td>
                    <img
                      class="item-img-table"
                      :src="`${baseUrl}${user.userImagePath}`"
                      :onerror="`this.src='${defaultImgUser}'`"
                    />
                    {{ isDataExist(user.userNameCurrent) }}
                  </td>
                  <td>{{ isDataExist(user.fullCode) }}</td>
                  <td class="table-phone-number">
                    {{ isDataExist(user.userPhoneWithCC) }}
                  </td>
                  <td>{{ isDataExist(user.userEmail) }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="5">{{ $t("users.noUsers") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { BASE_URL } from "@/utils/constants";
import {
  setDataMultiLang,
  timeToLang,
  isDataExist,
  checkPrivilege,
} from "@/utils/functions";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      baseUrl: BASE_URL,
    };
  },
  props: ["brandUsers", "defaultImgBrand", "defaultImgUser"],
  methods: {
    setBrandUserData(diagnosisPlanData) {
      this.$emit("setBrandUserData", diagnosisPlanData);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang,
    isDataExist,
    checkPrivilege,
  },
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
  },
};
</script>

<style lang="scss"></style>
