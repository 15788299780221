<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="brandUsers.brandsIncludedUsersData">
      <BrandUsersTable
        v-on:setBrandUserData="brandUsers.brandUser.fillData($event)"
        :brandUsers="brandUsers.brandsIncludedUsersData"
        :defaultImgBrand="brandUsers.brandUser.defaultImgBrand"
        :defaultImgUser="brandUsers.brandUser.defaultImgUser"
      />

      <!-- <BrandUserInfo :brandUser="brandUsers.brandUser" /> -->

      <Pagination
        v-if="!isLoading"
        :paginationData="brandUsers.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <BrandUserFloatBtns
      :theFilterData="brandUsers.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "./../../utils/constants";
// import BrandUserCard from "@/components/brandUsers/BrandUserCard.vue";
import BrandUsersTable from "@/components/brandUsers/BrandUsersTable.vue";
// import BrandUserInfo from "@/components/brandUsers/BrandUserInfo.vue";
import BrandUserFloatBtns from "@/components/brandUsers/BrandUserFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import BrandUsers from "./../../models/brandUsers/BrandUsers";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    // BrandUserCard,
    BrandUsersTable,
    // BrandUserInfo,
    BrandUserFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      brandUsers: new BrandUsers(),
    };
  },
  methods: {
    changePage(page) {
      this.brandUsers.pagination.selfPage = page;
      this.getAllBrandsIncludedUsers();
    },
    search(data) {
      this.brandUsers.filterData.fillData(data);
      this.brandUsers.brandUsersData = [];
      this.getAllBrandsIncludedUsers();
    },
    async getAllBrandsIncludedUsers() {
      this.isLoading = true;
      try {
        const response =
          await this.brandUsers.brandUser.getAllBrandsIncludedUsers(
            this.language,
            this.userAuthorizeToken,
            this.brandUsers.pagination,
            this.brandUsers.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.brandUsers.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.brandUsers.brandsIncludedUsersData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.brandUsers.brandsIncludedUsersData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.brandUsers.brandsIncludedUsersData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.brandUsers.brandsIncludedUsersData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllBrandsIncludedUsers();
  },
};
</script>
