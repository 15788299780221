import PaginationData from "@/models/general/PaginationData";
import BrandUser from "./BrandUser";
import BrandUsersFilter from "./BrandUsersFilter";

export default class BrandUsers {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.brandsIncludedUsersData = [];
    this.brandUser = new BrandUser();
    this.filterData = new BrandUsersFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.brandsIncludedUsersData = data.brandsIncludedUsersData;
  }
}
